import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav } from 'react-bootstrap';
import styled from "styled-components";

const Header = () => {
  const [openDrawer, toggleDrawer] = useState(false);
  const drawerRef = useRef(null);

  useEffect(() => {
    const closeDrawer = event => {
      if (drawerRef.current && drawerRef.current.contains(event.target)) {
        return;
      }

      toggleDrawer(false);
    };

    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);
  }, []);

  return (
    <div className="adarshm__nav__main">
      <div className="adarshm__nav__desktop">
        <div className="container adarshm__container">
          <Navbar className="adarshm__header">
            <Navbar.Brand className="adarshm__link" href="#home">adarsh.m</Navbar.Brand>
            <Nav className="ml-auto adarshm__nav">
              <Nav.Link className="adarshm__link" href="#home">00. Home</Nav.Link>
              <Nav.Link className="adarshm__link" href="#pricing">01. Blog</Nav.Link>
              <Nav.Link className="adarshm__link" href="#pricing">02. About</Nav.Link>
              <Nav.Link className="adarshm__link" href="#pricing">03. Contact</Nav.Link>
              <Nav.Link className="adarshm__link adarshm__resume" href="#pricing">Resume</Nav.Link>
            </Nav>
          </Navbar>
        </div>
      </div>

      <div className="adarshm__nav__mobile">
        <Styles.Wrapper>

          <NavBar.Wrapper>
            <NavBar.Logo>adarsh.m</NavBar.Logo>

            <HamburgerButton.Wrapper onClick={() => toggleDrawer(true)}>
              <HamburgerButton.Lines />
            </HamburgerButton.Wrapper>

            <NavBar.Items ref={drawerRef} openDrawer={openDrawer}>
              <NavBar.Item>Home</NavBar.Item>
              <NavBar.Item>Blog</NavBar.Item>
              <NavBar.Item>About</NavBar.Item>
            </NavBar.Items>
          </NavBar.Wrapper>
        </Styles.Wrapper>
      </div>
    </div>
  )
}

const Styles = {
  Wrapper: styled.main`
    display: flex;
    background-color: #eeeeee;
  `
};

const NavBar = {
  Wrapper: styled.nav`
    display: flex;
    flex: 1;
    align-self: flex-start;
    padding: 9px;
    justify-content: space-between;
    align-items: center;
    background-color: #330033;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    position: fixed;
    width: 100vw;
    top: 0;
    padding: 1rem;
    z-index: 2;
  `,
  Logo: styled.h1`
    padding: 0.5rem 1rem;
    color: #64ffda;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    flex-direction: column;
    background-color: #330033;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    width: 64%;
    padding: 1rem 2rem;
    transition: 0.2s ease-out;
    transform: ${({ openDrawer }) =>
      openDrawer ? `translateX(0)` : `translateX(100%)`};
  `,
  Item: styled.li`
    cursor: pointer;
      padding: 1rem 0;
      background: transparent;
      color: #c4c4c4;
  `
};

const HamburgerButton = {
  Wrapper: styled.button`
    height: 3rem;
    width: 3rem;
    position: relative;
    font-size: 12px;
    display: block;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 150%;
      width: 150%;
      top: -25%;
      left: -25%;
    }
  `,
  Lines: styled.div`
    top: 50%;
    margin-top: -0.125em;
    &,
    &:after {
      height: 2px;
      pointer-events: none;
      display: block;
      content: "";
      width: 160%;
      right: 0;
      background-color: #ffffff;
      position: absolute;
    },
    &:before {
      height: 2px;
      pointer-events: none;
      display: block;
      content: "";
      width: 40%;
      right: 0;
      background-color: #ffffff;
      position: absolute;
    }

    &:after {
      top: -0.8rem;
    }

    &:before {
      top: 0.8rem;
    }
  `
};


export default Header;
