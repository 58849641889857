import React from "react";
import Header from "./components/Header";
import ContainerSection from "./components/ContainerSection";
import About from "./components/About";
import WorkDetails from "./components/WorkDetails";
import WhatIamProudOf from "./components/WhatIamProudOf";
import Spacing from "./components/Spacing";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Spacing />
      <ContainerSection />
      <Spacing />
      <Spacing />
      <About />
      <Spacing />
      <Spacing />
      <WorkDetails />
      <Spacing />
      <Spacing />
      <WhatIamProudOf />
      <Spacing />
      <Spacing />
      <ContactForm />
      <Spacing />
      <Footer />
    </div>
  );
}

export default App;
