import React from "react";
import Tab from "react-bootstrap/Tab";
import WorkCard from "./WorkCard";
import { Nav, Col } from "react-bootstrap";

const WorkDetails = ({ employer, eventKey }) => {
  const employers = [
    {
      employer: "CodeAce",
      eventKey: "second",
    },
    {
      employer: "Lendingkart",
      eventKey: "third",
    },
    {
      employer: "BlogBing",
      eventKey: "forth",
    },
  ];

  let codeace = [
    "Designed 20+ websites in WordPress.",
    "Built High Performance websites using Vanilla JavaScript.",
    "Managed & Migrated Servers (All are Linux based).",
  ];

  let lk = [
    "Achived a load time of 1.9s for the website, it was at 18s before.",
    "Built multiple calculators using JavaScript.",
    "Collaborated with UX, Product and Marketing team.",
    "Worked on security of website and was able successfully patch every possible area.",
  ];

  let bb = [
    "Worked on Brand awareness.",
    "Learned and successfully implemented Ads on Facebook and other platforms.",
  ];

  return (
    <div className="WorkDetails container">
      <h2 className="adarshm__about__heading">02. Where I’ve Worked</h2>
      <Tab.Container
        className="container"
        id="left-tabs-example"
        defaultActiveKey="first"
      >
        <div className="wordDetails__container">
          <Col sm={9}>
            <Nav variant="pills">
              {employers.map((emp, index) => {
                return (
                  <Nav.Item key={emp.eventKey}>
                    <Nav.Link eventKey={emp.eventKey}>{emp.employer}</Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>

          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="second">
                <WorkCard
                  position={"Web Developer"}
                  name={"CodeAce IT Solutions"}
                  startdate={"September 2021"}
                  currentstatus={"March 2021"}
                  achivements={codeace}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <WorkCard
                  position={"Web Developer"}
                  name={"Lendingkart Technologies"}
                  startdate={"April 2019"}
                  currentstatus={"July 2020"}
                  achivements={lk}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="forth">
                <WorkCard
                  position={"Brand Strategist"}
                  name={"BlogBing Solution"}
                  startdate={"July 2018"}
                  currentstatus={"December 2018"}
                  achivements={bb}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </div>
      </Tab.Container>
    </div>
  );
};

export default WorkDetails;
