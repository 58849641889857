import React from "react";

const About = () => {
  return (
    <div className="adarshm__aboutcontainer container">
      <div className="adarshm__about">
        <h2 className="adarshm__about__heading">01. About Me</h2>

        <div className="adarshm__about__img">
          <div className="adarshm__about__text">
            <p className="adarshm__description__full">
              Hey, I am Adarsh M and I make things for the web. I started
              showing my interest in web development during my school days,
              where there was a competition in which we have to build an HTML &
              CSS website on given topic, and that's it! I found it interesting,
              and it was amazing. And I started to do freelancing from the year
              2015 and then.
            </p>

            <ul className="skills-list">
              <li>JavaScript (ES6+)</li>
              <li>HTML/CSS</li>
              <li>Adobe Photoshop</li>
              <li>React JS</li>
              <li>WordPress</li>
              <li>GIT</li>
              <li>NodeJS</li>
              <li>Cyber Security</li>
              <li>SEO</li>

            </ul>
          </div>

          <div className="adarshm__image">
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
