import React from 'react'

const WorkCard = ({ position, name, startdate, currentstatus, achivements }) => {
  return (
    <div className="workCard__container">
      <div className="layer__one">
        <h2 className="workCard__position">{position}</h2>
        <h2 className="workCard__at">&nbsp; @ &nbsp;</h2>
        <h2 className="workCard__location">{name}</h2>
      </div>
      <div className="layer__one">
        <p className="wordCard__startdate">{startdate}</p>
        <p className="wordCard__to">&nbsp; - &nbsp;</p>
        <p className="wordCard__currentStatus">{currentstatus}</p>
      </div>
      <div className="layer__two">
        {achivements.map((achivement, index) => {
          return (
            <p className="achivements" key={index}>{achivement}</p>
          )
        })}
      </div>
    </div>
  )
}

export default WorkCard;
