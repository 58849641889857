import React from 'react'
import styled from 'styled-components'

const Spacing = () => {
  return (
    <Spacehere></Spacehere>
  )
}

export default Spacing;

const Spacehere = styled.div`
  min-height: 14vh;
  max-height: 40vh;
  @media only screen and (max-width: 767px) {
    min-height: 10vh;
    max-height: 20vh;
  }
`;
