import React from "react";

const WhatIamProudOf = ({ name, description, tech, link }) => {
  let data = [
    {
      name: "Tesla Clone using ReactJS",
      description: "Tesla official website home page clone using ReactJS. Used useState, useEffect and props.",
      link: "https://github.com/adarshm07/reactjs-tesla-clone",
      tech: ["ReactJS, JSX, CSS"],
    },
    {
      name: "Food Recipe Search using ReactJS",
      description: "Food Recipe search website. The data is coming from an API. Used useState, useEffect and props.",
      link: "https://github.com/adarshm07/reactjs-recipe-search",
      tech: ["ReactJS, JSX, CSS"],
    },
    {
      name: "Tic Tac Toe using ReactJS",
      description: "Tic Tac Toe Game built using ReactJS.",
      link: "https://github.com/adarshm07/reactjs-tic-tac-toe",
      tech: ["ReactJS, JSX, CSS"],
    },
  ];

  return (
    <div className="github__latest__container container">
      <h2 className="adarshm__about__heading">03. Something You Would Like</h2>
      <ul className="github__grid">
        {data.map((d, index) => {
          return (
            <li className="github__list" key={index}>
              <div className="inner__container">
                <div className="layer__top">
                  <div className="folder">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-folder"
                    >
                      <title>Folder</title>
                      <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                    </svg>
                  </div>

                  <div className="github__links">
                    <a
                      href={d.link}
                      className="a__link"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-external-link"
                      >
                        <title>External Link</title>
                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                        <polyline points="15 3 21 3 21 9"></polyline>
                        <line x1="10" y1="14" x2="21" y2="3"></line>
                      </svg>
                    </a>
                  </div>
                </div>

                <div className="layer__center">
                  <h3 className="github__title">
                    <a href={d.link} rel="noopener noreferrer" target="_blank">
                      {d.name}
                    </a>
                  </h3>
                  <div className="a__link" className="project-description">
                    <p>{d.description}</p>
                  </div>
                </div>

                <div className="layer__bottom">
                  <ul className="tech__list">
                    <li key={d.index}>{d.tech}</li>
                  </ul>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default WhatIamProudOf;
